import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Breadcrumb, Button } from "antd";
import navigationConfig from "configs/NavigationConfig";
import IntlMessage from "components/util-components/IntlMessage";
import { useHistory } from "react-router-dom";

let breadcrumbData = {
  "": <IntlMessage id="home" />,
};

const createBreadcrumbData = (location) => {
  navigationConfig.forEach((elm, i) => {
    const assignBreadcrumb = (obj) => {
      // Check if paty is an array

      if (Array.isArray(obj.allowed_paths)) {
        for (var i = 0; i < obj.allowed_paths.length; i++) {
          // if (obj.allowed_paths[i].breadcrumb !== false) {
          breadcrumbData[obj.allowed_paths[i].path] = (
            <IntlMessage key={`Int-b${i}`} id={obj.allowed_paths[i].title} />
          );
          // }

          if (obj.allowed_paths[i].path.includes(".*")) {
            // if (obj.allowed_paths[i - 1]?.breadcrumb === false) isHidden = true;
            //let reg = new RegExp(obj.allowed_paths[i].path.replace("*", "([sS]*?)"));
            let reg = new RegExp(obj.allowed_paths[i].path);

            let res = location.match(reg);

            if (res) {
              if (res.length > 0)
                breadcrumbData[location] = <IntlMessage key={`Int-a${i}`} id={obj.allowed_paths[i].title} />;
            }
            //if (reg.test(location)) breadcrumbData[location] = <IntlMessage id={obj.allowed_paths[i].title} />;
          }
        }
      }

      return (breadcrumbData[obj.path] = <IntlMessage key={`Int-c${obj.title}`} id={obj.title} />);
    };

    assignBreadcrumb(elm);

    if (elm.submenu) {
      elm.submenu.forEach((elm) => {
        assignBreadcrumb(elm);

        if (elm.submenu) {
          elm.submenu.forEach((elm) => {
            assignBreadcrumb(elm);
          });
        }
      });
    }
  });
};

const createBreadcrumbItem = (url, index) => {
  let t = breadcrumbData[url];

  if (t === undefined) return;

  return (
    <Breadcrumb.Item key={`${url}-${index}`}>
      <Link to={url}>{breadcrumbData[url]}</Link>
    </Breadcrumb.Item>
  );
};

const BreadcrumbRoute = withRouter((props) => {
  let history = useHistory();
  const { location } = props;

  if (location?.pathname?.includes("/pier-dashboard")) return <></>;

  //console.log("location", location.pathname);

  createBreadcrumbData(location.pathname);

  const pathSnippets = location.pathname.split("/").filter((i) => i);

  let buildBreadcrumb = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;

    return createBreadcrumbItem(url, index);
  });

  // Filter all undefined Breadcrumbs
  buildBreadcrumb = buildBreadcrumb.filter((el) => el !== undefined);

  const buttonName = (link) => {
    //console.log("buttonName", link);
    switch (link) {
      // case "/service":
      //   return "Novo Serviço";

      case "/entry":
        return "Nova Consulta";

      case "/global/equipment":
        return "Novo Equipamento";

      case "/global/avatar":
        return "Novo Avatar";

      case "/global/qualification":
        return "Novo Qualificação";

      case "/global/pier":
        return "Novo Cais";

      case "/global/staff":
        return "Novo Staff";

      case "/invoicing/entity":
        return "Novo Entidade";

      case "/comercial/action":
        return "Nova Ação";

      default:
        return "Novo Registo";
    }
  };

  const new_link = pathSnippets.map((_, index) => `/${pathSnippets.slice(0, index - 1).join("/")}`);
  //console.log(pathSnippets);
  return (
    <React.Fragment>
      <div style={{ width: "100%" }}>
        <div style={{ float: "left", paddingTop: 10 }}>
          <Breadcrumb key={"main"}>{buildBreadcrumb}</Breadcrumb>
        </div>

        {pathSnippets.includes("list") && pathSnippets[0] !== "service" && (
          <div style={{ float: "right" }}>
            <Button type="primary" onClick={() => history.push(`${new_link[0]}/new`)}>
              {buttonName(new_link[0])}
            </Button>
          </div>
        )}
      </div>
    </React.Fragment>
  );
});

export class AppBreadcrumb extends Component {
  render() {
    return <BreadcrumbRoute />;
  }
}

export default AppBreadcrumb;
