export const accessTypes = [
  "Administrador",
  "Gestor",
  "Administrativo",
  "Secretariado",
  //"Marketing",
  "Cais",
  "Comprador",
  "Mobile: Despesas",
  "Ocupação (ViewOnly)",
];

const rolesRank = {
  Administrador: 5,
  Gestor: 4,
  Administrativo: 3,
  //Marketing: 3,
  Secretariado: 2,
  Cais: 1,
  Comprador: 0,
  "Mobile: Despesas": 0,
  "Ocupação (ViewOnly)": -1,
};

export const getRoleRank = (roles) => {
  let rank = 1;
  if (!roles) return rank;

  roles.forEach((role) => {
    if (rolesRank[role] > rank) {
      rank = rolesRank[role];
    }
  });

  return rank;
};
